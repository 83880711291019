import { auth } from '../../firebase';
import { getAuthToken } from './auth';

const createApiInstance = (baseUrl) => {
  // Default headers to be included in each request
  let defaultHeaders = {
    'Content-Type': 'application/json',
    // Include any other common headers if needed
  };

  const apiRequest = async (method, url, body = null, options = {}) => {
    // Get the auth token from the singleton
    const authToken = getAuthToken();

    // If the auth token is present, add it to the headers
    if (authToken) {
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${authToken}`,
      };
    }

    // Merge headers from options with default headers
    const headers = {
      ...defaultHeaders,
      ...options.headers, // Merge additional headers if provided
    };

    // Prepare the config for the fetch request
    const config = {
      method,
      headers, // Ensure the headers are correctly merged
      mode: 'cors',
      ...options, // Spread other possible options like `mode`, `cache`, etc.
    };

    // Add body if it's present (only for POST, PUT, PATCH requests)
    if (body) {
      config.body = JSON.stringify(body); // Convert body to JSON string if provided
    }

    try {
      // Make the fetch request with dynamic base URL and the provided endpoint
      const response = await fetch(`${baseUrl}/${url}`, config);
      // Check if the response is not OK (status code outside the 2xx range)
      if (!response.ok) {
        // try refresh in case token expired
        if (response.status === 401) {
          const newToken = await auth.currentUser.getIdToken(true);
          const refreshConfig = {
            ...config,
            headers: {
              ...headers,
              Authorization: `Bearer ${newToken}`,
            },
          };
          const refreshResponse = await fetch(
            `${baseUrl}/${url}`,
            refreshConfig
          );

          if (!refreshResponse.ok) {
            // can sign out the user if needed
            throw new Error(
              `Refresh token failed: ${refreshResponse.status} - ${refreshResponse.statusText}`
            );
          }

          return refreshResponse.json();
        }

        const errorData = await response.json();
        const errorMessage = errorData.message || response.statusText;
        throw new Error(`Error: ${response.status} - ${errorMessage}`);
      }

      // Return the parsed JSON response
      return await response.json();
    } catch (error) {
      if (error.name === 'AbortError') {
        throw new Error('Request was aborted.');
      } else if (error instanceof TypeError) {
        // TypeError typically occurs due to network errors or CORS issues
        throw new Error('Network error or CORS issue.');
      } else {
        throw error;
      }
    }
  };

  // Return an object with methods for API requests and dynamic headers
  return {
    get: (url, options = {}) => apiRequest('GET', url, null, options),
    post: (url, body, options = {}) =>
      apiRequest('POST', url, body, options),
    put: (url, body, options = {}) => apiRequest('PUT', url, body, options),
    patch: (url, body, options = {}) =>
      apiRequest('PATCH', url, body, options),
    delete: (url, body = null, options = {}) =>
      apiRequest('DELETE', url, body, options),
    setHeader: (name, value) => {
      defaultHeaders = {
        ...defaultHeaders,
        [name]: value,
      };
    },
    getHeaders: () => defaultHeaders,
  };
};

// Export a function to create API instances.
export const getApiInstance = (serviceUrl) => createApiInstance(serviceUrl);
