import { useRef, useState, useEffect } from 'react';
import { Box, Center, Input, Text } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useViewport } from '../utils';
import { maxDate } from '../utils/helpers/maxDate';

export const HookedDateInput = ({ label, withLabel = false, placeholder, disabled, ...props }) => {
  const [visibleLabel, setLabelVisible] = useState(true);
  const ref = useRef(null);
  const { isMobile } = useViewport();
  const today = maxDate();

  useEffect(() => {
    if (disabled) {
      setLabelVisible(false);
    }
  }, [disabled]);

  return (
    <Controller
      {...props}
      render={({ field: { onChange, value } }) => (
        <Box w="100%" position="relative">
          <Center
            position="absolute"
            top="4px"
            right="40px"
            bottom={visibleLabel ? '4px' : '40px'}
            left="4px"
            fontSize={visibleLabel ? 'sm' : isMobile ? '0' : 'xs'}
            backgroundColor="white"
            zIndex={3}
            onClick={() => {
                ref.current.focus();
            }}
          >
            <Text w="100%" textAlign="left" pl="6px" color="gray.500">
              {placeholder}
            </Text>
          </Center>
          <Input
            onFocus={() => setLabelVisible(false)}
            onBlur={() => setLabelVisible(!value?.length)}
            onChange={(v) => {
              // set to undefined to remove the form error "" is no a valid date
              const value = (!v.target.value) ? undefined : v.target.value;
              onChange(value)
            }}
            value={value}
            placeholder={placeholder}
            borderColor="gray.200"
            borderWidth="1px"
            borderRadius={1}
            w="100%"
            align="start"
            h={['40px', '40px', '40px', '60px']}
            px={2}
            fontSize={['small', 'small', 'small', 'medium']}
            pt={withLabel ? '30px' : '0px'}
            pb={withLabel ? '20px' : '0px'}
            type="date"
            disabled={disabled}
            max={today}
            zIndex={2}
            ref={ref}
          />
        </Box>
      )}
    />
    );
};
