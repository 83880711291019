import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import customerQueries from '../../../hooks/queries/customer';
import StyledRadioButtons from '../../../shared/StyledRadioButtons';
import { START_QUIZ } from '../../../utils';
import MessageModal from './ModalContent/MessageModal';

const messages = {
  puzzleMaster: "CONGRATULATIONS, PUZZLE MASTER! 🎯 You're one of the first 100 members to crack the code on this challenge. Your quick wit and sharp mind just earned you bonus points! Keep flexing those mental muscles - more thrilling challenges await.",
  puzzlePro: "GREAT EFFORT, PUZZLE PRO! 🧩 While you mastered this challenge, the bonus points have already been claimed. But don't let that stop you - your sharp mind is exactly what we need for future quizzes. Stay ready for the next challenge!",
  puzzleRocky: "PUZZLE MASTER IN TRAINING! 🧩 While this challenge eluded you this time, we know you're building skills for the next brain-teaser. Keep that problem-solving spirit alive - more exciting quizzes are coming soon with fresh chances to earn points!",
  alreadyDone: "This quiz was already answered by the customer correctly",
};

const QuizModal = ({ user, quizId, quizIds, isOpen, onClose }) => {
  const toast = useToast();
  const [answers, setAnswers] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoading, onLoading] = useState(false);
  const { data: quiz, isSuccess } = customerQueries.useGetQuiz(
    user?.a_id ?? '',
    quizIds
  );
  const { mutateAsync: validateQuizQuestion } =
    customerQueries.useValidateQuizQuestion();
  const { mutateAsync: checkChallengeStatus } = customerQueries.useCheckChallengeStatus();

  const [totalSteps, setTotalSteps] = useState(
    quiz?.questions.length + 1 ?? 0
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [historyAnswer, setHistoryAnswer] = useState([]);


  useEffect(() => {
    setTotalSteps(quiz?.questions.length + 1);
  }, [quiz, isSuccess]);

  const goToNextStep = useCallback(async () => {
    if (currentStep < totalSteps) {
      if (selectedAnswer !== undefined) {
        try {
          onLoading(true);
          const ha = [...historyAnswer];
          ha[currentStep - 1] = selectedAnswer;
          setHistoryAnswer([...ha])

          const res = await validateQuizQuestion({
            userId: user?.a_id ?? '',
            quizId: quiz?.questions[currentStep - 1].id,
            quizAnswer: selectedAnswer,
          });
          const { correct, completed, alreadyDone } = res;
          answers[currentStep - 1] = {
            correct, completed, alreadyDone
          };
          setAnswers(answers);
        } catch (error) {
          console.log('there was an error', error);
        }
        setSelectedAnswer();
        setCurrentStep(currentStep + 1);
        onLoading(false);
      } else {
        toast({
          title: 'Please select an answer',
          status: 'info',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    if (currentStep === quiz?.questions.length) {
      checkChallengeStatus({ userId: user?.a_id, challchallenge_id: quizId }).then(({ first_hundred }) => {
        if (first_hundred) {
          if (answers?.every(({alreadyDone}) => alreadyDone)) {
            setMessage(messages.alreadyDone);
          } else {
            setMessage(messages.puzzleMaster);
          }
        } else if (answers?.some(({correct}) => !correct)) {
          setMessage(messages.puzzleRocky);
        } else if (!first_hundred) {
          setMessage(messages.puzzlePro);
        }
        setAnswers([]);
      });
    }
  }, [answers, checkChallengeStatus, currentStep, quiz, quizId, selectedAnswer, toast,totalSteps, user, validateQuizQuestion, historyAnswer]);

  // const goToPrevStep = () => {
  //   if (currentStep > 1) {
  //     setSelectedAnswer(historyAnswer[currentStep - 2]);
  //     setCurrentStep(currentStep - 1);
  //   }
  // };

  const startQuiz = () => {
    setCurrentStep(currentStep + 1);
  };

  const completeQuiz = () => {
    onClose();
    setCurrentStep(0);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={['md', 'lg']}
    >
      <ModalOverlay />
      <ModalContent overflow={'auto'}>
        <ModalHeader mt={4}>
          <Text
            justifySelf={'center'}
            fontWeight="bold"
            fontSize={'xx-large'}
            color="teal.900"
          >
            RedDoor Flash Quiz
          </Text>
          <ModalCloseButton
            borderRadius="full"
            bg="brand.green"
            color="white"
            fontSize="sm"
            mt={3}
            _hover={{
              bg: 'brand.green',
              borderColor: 'red.600',
            }}
          />
        </ModalHeader>
        {currentStep === 0 || currentStep === totalSteps ? (
          <MessageModal
            displayText={currentStep === 0 ? START_QUIZ : message}
            onClickHandler={
              currentStep === 0 ? startQuiz : completeQuiz
            }
            buttonText={currentStep === 0 ? 'START' : 'COMPLETE'}
          />
        ) : (
          <>
            <ModalBody
              minHeight={[
                '300px',
                '300px',
                '300px',
                '300px',
                '300px',
              ]}
              minWidth={[
                '400px',
                '400px',
                '400px',
                '450px',
                '450px',
              ]}
            >
              {/* custom progress bar */}
              <Grid
                templateColumns={`repeat(${
                  totalSteps - 1
                }, 1fr)`}
                gap={'2px'}
                mb={6}
              >
                {Array.from(
                  { length: totalSteps - 1 },
                  (_, index) => (
                    <GridItem
                      key={index}
                      h="15px"
                      bg={
                        index < currentStep
                          ? 'brand.green'
                          : '#A8B8BC'
                      }
                    />
                  )
                )}
              </Grid>
              <Text ml={2} fontWeight={500} fontSize="lg" mb={4}>
                {currentStep}.{' '}
                {quiz?.questions[currentStep - 1].title}
              </Text>
              <StyledRadioButtons
                options={
                  quiz?.questions[currentStep - 1].answers
                }
                onChange={(answer) => {
                  setSelectedAnswer(quiz.questions[currentStep - 1].answers.indexOf(answer));
                }}
              ></StyledRadioButtons>
            </ModalBody>
            <ModalFooter justifyContent={'flex-end'}>
              {/*<Button
                isDisabled={currentStep === 1}
                _disabled={{
                  opacity: 0.5,
                  cursor: 'not-allowed',
                  bg: 'brand.red',
                }}
                _hover={{
                  transform: 'scale(1.05)',
                  transition: 'all 0.3s ease',
                }}
                onClick={goToPrevStep}
              >
                Prev
              </Button>*/
              <></>}
              <Button
                ml={5}
                onClick={goToNextStep}
                _hover={{
                  transform: 'scale(1.05)',
                  transition: 'all 0.3s ease',
                }}
                isLoading={isLoading}
              >
                {currentStep === totalSteps - 1
                  ? 'Finish'
                  : 'Next'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default QuizModal;
