import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Center,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useViewport } from '../../utils';
import ListContainer from './ListContainer';

const mapActivities = (data) =>
  data?.map((item, i) => ({
    title: item?.label,
    date: new Intl.DateTimeFormat('en-US', {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
    }).format(new Date(item?.timestamp)),
    points: item?.points?.toLocaleString(),
    // eslint-disable-next-line no-useless-escape
    color: !!`${item?.points}`?.match(/^\-/)?.length
      ? 'brand.red'
      : 'brand.green',
    timestamp: new Date(
      new Date(item?.timestamp).toISOString().split('T')[0]
    ),
  }));

const filterActivitiesByDate = (data, { startDate, endDate }) =>
  data?.filter(
    ({ timestamp }) => startDate <= timestamp && timestamp <= endDate
  );

const dateTwelveMonthsAgo = () => {
  const today = new Date();
  return new Date(today.setFullYear(today.getFullYear() - 1));
};

function ActivityHistory({ user, customerActivities }) {
  const { isMobile, isLandscape } = useViewport();
  const [openDateRange, setOpenDateRange] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: dateTwelveMonthsAgo(),
    endDate: new Date(),
    key: 'selection',
    color: '#E82C2A',
  });

  const { startDate, endDate } = selectionRange;

  const formattedStartDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  }).format(startDate);
  const formattedEndDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  }).format(endDate);

  const filterActivities = (activities) =>
    activities?.filter((activity) => !['profile', 'quiz'].includes(activity.action));

  const activities = useMemo(() => {
    const filteredActivities = filterActivities(customerActivities);
    const parsedData = mapActivities(filteredActivities);
    return filterActivitiesByDate(parsedData, { startDate, endDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerActivities, customerActivities?.length, startDate, endDate]);

  return (
    <VStack w="100%">
      <Flex direction="column" width="100%" padding={4}>
        <HStack
          width="100%"
          backgroundColor="brand.red"
          py={[0, 4]}
          gap={3}
          justify="center"
          alignItems={['center', 'center', 'center', 'center']}
        >
          <CalendarIcon
            color="white"
            width={8}
            height={8}
            onClick={() => setOpenDateRange(!openDateRange)}
          />
          <Text
            color="white"
            fontSize={['large', 'xx-large']}
            fontWeight={700}
            onClick={() => setOpenDateRange(!openDateRange)}
          >
            {formattedStartDate} - {formattedEndDate}
          </Text>
          <Popover isOpen={openDateRange} closeOnBlur={false}>
            <PopoverTrigger>
              <IconButton
                p={0}
                m={0}
                minW={0}
                aria-label={openDateRange ? 'down' : '>'}
                onClick={() => setOpenDateRange(!openDateRange)}
                icon={
                  openDateRange ? (
                    <ChevronDownIcon
                      width={
                        isMobile && !isLandscape
                          ? 6
                          : 14
                      }
                      height={
                        isMobile && !isLandscape
                          ? 6
                          : 14
                      }
                      color="white"
                    />
                  ) : (
                    <ChevronRightIcon
                      color="white"
                      width={
                        isMobile && !isLandscape
                          ? 6
                          : 14
                      }
                      height={
                        isMobile && !isLandscape
                          ? 6
                          : 14
                      }
                    />
                  )
                }
              />
            </PopoverTrigger>
            <PopoverContent w="350px">
              <PopoverArrow />
              <PopoverCloseButton
                onClick={() => setOpenDateRange(false)}
              />
              <PopoverBody w="350px">
                <DateRange
                  className="rd-date-range"
                  ranges={[selectionRange]}
                  showDateDisplay={false}
                  onChange={({ selection }) =>
                    setSelectionRange(selection)
                  }
                  color="#E82C2A"
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>

        {activities?.length ? (
          <ListContainer items={activities} itemsPerPage={10} />
        ) : (
          <Center w="100%" h={[300, 300, 300, 500]}>
            <Text
              textAlign="center"
              fontWeight={500}
              fontSize={[
                'x-large',
                'x-large',
                'x-large',
                'xxx-large',
              ]}
              color="brand.red"
            >
              No activity on the selected time period
            </Text>
          </Center>
        )}
      </Flex>
    </VStack>
  );
}

export default ActivityHistory;
